import CommonRecommendGames, { RecommendGamesProps } from '@/features/recreation/component/recommend-games'
import classNames from 'classnames'
import styles from './index.module.css'

export default function RecommendGamesOk(props: RecommendGamesProps) {
  return (
    <CommonRecommendGames
      {...props}
      gap={16}
      perPage={6}
      hiddenShowMore={false}
      showMoreCard={false}
      paddingRight={0}
      className={classNames(props?.className, styles['recommend-games-ok-root'])}
    />
  )
}
