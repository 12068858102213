import { oss_svg_image_domain_address } from '@/constants/oss'
import cs from 'classnames'

import { t } from '@lingui/macro'
import styles from './index.module.css'

interface IGoMoreCardProps {
  className?: string
  onMore?: () => void
}

function GoMoreCard({ className, onMore }: IGoMoreCardProps) {
  const bgImg = `${oss_svg_image_domain_address}more_card.png`

  return (
    <div className={cs(styles['go-more-card'], className)}>
      <div className="item" style={{ backgroundImage: `url(${bgImg})` }} onClick={onMore}>
        {t`features/trade/trade-order/index-0`}
      </div>
    </div>
  )
}

export default GoMoreCard
