import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { RecommendGamesProps } from '@/features/recreation/component/recommend-games'
import RecommendGames2z from '@/features/recreation/theme/2z/recommend-games'
import RecommendGamesOk from '@/features/recreation/theme/ok/recommend-games'

export default function RecommendGames(props: RecommendGamesProps) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <RecommendGames2z {...props} />
    case InitThemeColor.ok:
      return <RecommendGamesOk {...props} />
    default:
      return <RecommendGames2z {...props} />
  }
}
