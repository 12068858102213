/**
 * 推荐游戏
 */
import { t } from '@lingui/macro'
import Icon from '@/components/icon'
import classNames from 'classnames'
import { link } from '@/helper/link'
import {
  GameListBreakpoints,
  GameListPaddingRight,
  GameListPageSize,
  LayoutScrollTop,
  RaGameTypeEnum,
} from '@/constants/game'
import { memo, useEffect, useRef, useState } from 'react'
import { useUpdateEffect } from 'ahooks'
import Splide, { Options } from '@splidejs/splide'
import { Button, Image } from '@nbit/arco'
import { RaHallGameCardTypeProps, GameGroupSupplierTypeProps } from '@/typings/api/game'
import { getUUId } from '@/helper/common'
import { SymbolsEnum } from '@/constants/common'
import styles from './index.module.css'
import SupplierGame from '../supplier-game'
import '@splidejs/splide/dist/css/splide.min.css'
import GoMoreCard from '../go-more-card'
import GameCell from '../../theme/components/game-cell'

export type RecommendGamesProps = {
  /** 游戏类型 */
  type?: string
  /** 推荐模块标题 */
  title?: string
  /** 推荐模块标题图标 */
  titleIcon?: string
  /** 样式 */
  className?: string
  /** 推荐模块标题图标是否图片 */
  isImage?: boolean
  /** 是否隐藏标题栏查看更多按钮 */
  hiddenShowMore?: boolean
  /** 每页展示的游戏卡片数量 */
  perPage?: number
  /** 设置 paddingRight 可以提前展示下一页数据 */
  paddingRight?: number | string
  /** 滑动组件相关参数 */
  breakpoints?: Options['breakpoints']
  gap?: Options['gap']
  /** 查看全部（推荐图标点击事件） */
  onMore?: () => void
  loading?: boolean
  /** 游戏卡片点击事件 */
  onEnterChange?: (v) => void
  /** 游戏列表 */
  gamesData?: RaHallGameCardTypeProps[] | GameGroupSupplierTypeProps[]
  /** 当切换按钮都 disabled 的时候隐藏切换按钮 */
  hiddenSwitchWhenBothDisabled?: boolean
  /** 在列表末尾追加查看更多卡片 */
  showMoreCard?: boolean
}

function CommonRecommendGames(props: RecommendGamesProps) {
  const {
    hiddenShowMore,
    gamesData,
    title,
    type,
    titleIcon,
    className,
    isImage,
    perPage = GameListPageSize,
    paddingRight = GameListPaddingRight,
    breakpoints = GameListBreakpoints,
    gap = 11,
    hiddenSwitchWhenBothDisabled = false,
    showMoreCard = false,
    onMore,
    onEnterChange,
  } = props
  const banners = gamesData?.length
    ? gamesData
    : [...new Array(perPage + 1)].map(() => ({
        name: '',
      }))
  const splideId = useRef<string>(`splide-${getUUId()}`)

  const [isPrev, setIsPrev] = useState(false) // 是否可以切换上一页
  const [isNext, setIsNext] = useState(true) // 是否可以切换下一页
  const [isSlided, setIsSlided] = useState(false) // 是否滑动已完成
  const splideRef = useRef<InstanceType<typeof Splide>>()
  const [currentSize, setCurrentSize] = useState<number>(perPage)

  const dataTotal = (gamesData?.length || 0) + Number(showMoreCard)

  useEffect(() => {
    if (!splideRef.current && splideId.current) {
      splideRef.current = new Splide(`#${splideId.current}`, {
        type: 'slide',
        perPage,
        rewind: false,
        // arrows: false,
        pagination: false,
        trimSpace: 'move',
        padding: { left: 0, right: paddingRight },
        gap,
        breakpoints,
      })

      splideRef.current.mount()
      setIsNext(splideRef.current.index / perPage < dataTotal / perPage - 1)
      setIsSlided(true)

      splideRef.current.on('arrows:updated', (prev, next) => {
        const isPrevDisabled = prev.hasAttribute('disabled')
        const isNextDisabled = next.hasAttribute('disabled')
        setIsPrev(!isPrevDisabled)
        setIsNext(!isNextDisabled)
        setCurrentSize(splideRef.current?.options.perPage || 0)
      })
    }

    return () => {
      if (splideRef.current) {
        splideRef.current.destroy()
        splideRef.current = undefined
      }
    }
  }, [gamesData])

  // 计算当前位置
  const calculatorCurrentIndex = () => {
    const currentIndex = splideRef.current?.index || 0
    const currentPerPage = splideRef.current?.options.perPage || 0
    const newIsNext = Math.ceil(currentIndex / currentPerPage) < Math.ceil(dataTotal / currentPerPage - 1)
    setIsPrev(currentIndex > 0)
    setIsNext(newIsNext)
  }

  const adjustPaddingBeforeMove = (direction: string) => {
    const currentPerPage = splideRef.current?.options.perPage || 0
    const nextIndex = (splideRef.current?.index || 0) + currentPerPage
    const hasNextPage = Math.ceil(nextIndex / currentPerPage) < Math.ceil(dataTotal / currentPerPage - 1)
    if (direction === SymbolsEnum.greaterThan && !hasNextPage) {
      splideRef.current!.options = {
        padding: { left: paddingRight, right: 0 },
      }
    } else {
      splideRef.current!.options = {
        padding: { left: 0, right: paddingRight },
      }
    }
  }

  const onChangePage = (val: string) => {
    adjustPaddingBeforeMove(val)
    splideRef.current?.go(val)
    calculatorCurrentIndex()

    // 修改 padding 后重新渲染会有闪动问题，暂时注释
    // splideRef.current.on('moved', () => {
    //   setIsSlided(true)
    //   splideRef.current.options.padding.right = newIsNext ? '70px' : '0'
    //   splideRef.current.options.padding.left = newIsNext ? '0' : '70px'
    //   splideRef.current.emit('updated')
    // })
  }

  useUpdateEffect(() => {
    if (!splideRef.current) return
    splideRef.current && splideRef.current.on('move', () => setIsSlided(false))

    splideRef.current &&
      splideRef.current.on('moved', () => {
        calculatorCurrentIndex()
        setIsSlided(true)
      })
  }, [splideRef.current])

  /** 是否展示切换按钮 */
  const showSwitch = !hiddenSwitchWhenBothDisabled || isPrev || isNext
  const isProvider = type === RaGameTypeEnum.provider

  return (
    <div className={classNames(styles['recommend-games-root'], className)}>
      <div className="recommend-header">
        <div className="header-cell" onClick={() => onMore?.()}>
          {isImage ? (
            <Image src={titleIcon} preview={false} className="recommend-image" />
          ) : (
            <Icon name={titleIcon as string} className="recommend-icon" />
          )}
          <span className="recommend-name">{title}</span>
        </div>

        <div className="header-cell">
          {showSwitch && (
            <div className="switch-warp">
              <Icon
                name="icon_arrow_home_previous"
                className={classNames('switch-icon', {
                  disabled: !isPrev,
                })}
                onClick={() => isPrev && onChangePage(SymbolsEnum.lessThan)}
              />
              <div className="line" />
              <Icon
                name="icon_arrow_home_next"
                className={classNames('switch-icon', {
                  disabled: !isNext,
                })}
                onClick={() => isNext && onChangePage(SymbolsEnum.greaterThan)}
              />
            </div>
          )}
          {!hiddenShowMore && (
            <Button className="more-btn" shape="round" onClick={() => onMore?.()}>
              {t`features/message-center/messages-3`}
            </Button>
          )}
        </div>
      </div>
      <div id={splideId.current} className="splide">
        <div className="splide__track">
          <ul className="splide__list">
            {banners?.map((item, i: number) => {
              let isVisible = true
              if (isSlided) {
                const currentIndex = splideRef.current?.index || 0
                isVisible = i < currentIndex + currentSize // 判断当前索引后的 currentSize 个元素是否可见
              }

              switch (type) {
                case RaGameTypeEnum.provider:
                  return (
                    <SupplierGame
                      data={item}
                      isVisible={isVisible}
                      isHover={isSlided}
                      onNext={() => onChangePage(SymbolsEnum.greaterThan)}
                      className="splide__slide game-wrap supplier"
                      onEnter={v => {
                        LayoutScrollTop()
                        link(`/recreation/game-shows?supplierId=${v.id}&&title=${v.name}`)
                      }}
                      key={`${item?.id ? `${item?.id}${i}` : `${item?.groupId}${i}`}`}
                    />
                  )
                default:
                  return (
                    <GameCell
                      className="splide__slide game-wrap"
                      key={`${item?.id ? `${item?.id}${i}` : `${item?.groupId}${i}`}`}
                      data={item}
                      isHover={isSlided}
                      isVisible={isVisible}
                      onNext={() => onChangePage(SymbolsEnum.greaterThan)}
                      onEnter={v => onEnterChange?.(v)}
                    />
                  )
              }
            })}
            {showMoreCard && (
              <GoMoreCard className={classNames('splide__slide game-wrap', { supplier: isProvider })} onMore={onMore} />
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default memo(CommonRecommendGames)
